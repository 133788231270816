<template>
    <div class="portfolio-piece">
        <div class="scroll-header">
            <div class="inf-scroll">
                <div>
                    <h1>Anchor Point<span> Creative</span></h1>
                    <h1>Anchor Point<span> Creative</span></h1>
                </div>
            </div>
        </div>
        <h2>Brand Design | Logo | Stationary</h2>

        <div class="portfolio-cont">

            <div class="p-content-row-2">
                <div>
                    <figure class="medium-img">
                        <img alt="anchor point secondary logo" src="../assets/images/portfolio/ap-secondary.jpg">
                    </figure>
                </div>
                <div>
                    <p>In doing branding for Anchor Point Creative, the first step was to establish the studios unique advantage, which was their out-of-the-box thinking. In keeping with this, the 
                        logo was designed to reflect a little absurdity - the fly-away anchor perfectly capturing the essense of designers who refuse to be kept down. Additional elements were concepted 
                        which utilize a nautical theme.</p>
                </div>
            </div>
        
            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="anchor point logo" src="../assets/images/portfolio/ap-logo.jpg">
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="anchor point stationary set" src="../assets/images/portfolio/ap-mockup2.jpg">
                    <figcaption>This sample stationary set shows how this brand extends across the business</figcaption>
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="anchor point on computer screen" src="../assets/images/portfolio/ap-concept.jpg">
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="anchor point colored letterhead" src="../assets/images/portfolio/ap-mockup.jpg">
                </figure>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "PortfolioAnchorPoint",

}
</script>

<style lang="scss">

</style>